import React from "react"
import { graphql } from "gatsby"
import showdown from "showdown"
import Head from "../components/headEn"
import Layout from "../components/layoutEn"
import Hero from "../components/hero"
import Intro from "../components/intro"
import Card from "../components/card"
import Stats from "../components/stats"
import Quote from "../components/quote"
import News from "../components/news"

export const query = graphql`
  query {
    allFile(filter: {extension: {eq: "md"}, name: {eq: "en"}}) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              title
              hero {
                title
                content
                image
              }
              intro {
                title
                content
              }
              services {
                title
                image
                boxTitle
                boxContent
              }
              stats {
                number
                description
                subtitle
              }
              quote {
                content
                name
                subtitle
              }
              about {
                title
                image
                boxTitle
                boxContent
              }
              news {
                title
                newsItems {
                  title
                  content
                }
              }
              contact {
                title
                image
                boxTitle
                boxContent
              }
            }
          }
        }
      }
    }
  }
`

export default function Home(props) {
  const data = props.data.allFile.edges[0].node.childMarkdownRemark.frontmatter
  const converter = new showdown.Converter({'simpleLineBreaks': true})

  return (
    <Layout>
      <Head title={data.hero.title} description={data.hero.content} robots="index, follow" />
      <div id={data.title.replace(/\s+/g, '-').toLowerCase()}>
        <Hero
          title={data.hero.title}
          description={data.hero.content}
          image={data.hero.image.split('/assets/').pop().replace('.jpg', '')}
          objectFit="cover"
          objectPosition="50% 15%" />
        <Intro subHeading={data.intro.title} title={data.intro.content} />
      </div>
      <div id={data.services.title.replace(/\s+/g, '-').toLowerCase()}>
        <Card
          title={data.services.title}
          reversed={false}
          image={data.services.image.split('/assets/').pop().replace('.jpg', '')}
          ratio={5 / 4}
          cardTitle={data.services.boxTitle}
          text={converter.makeHtml(data.services.boxContent)} />
        <Stats items={data.stats} />
        <Quote text={data.quote.content} subHeading={data.quote.name} description={data.quote.subtitle} />
      </div>
      <Card
          id={data.about.title.replace(/\s+/g, '-').toLowerCase()}
          title={data.about.title}
          reversed={true}
          image={data.about.image.split('/assets/').pop().replace('.jpg', '')}
          ratio={4 / 3}
          cardTitle={data.about.boxTitle}
          text={converter.makeHtml(data.about.boxContent)} />
      <News title={data.news.title} newsItems={data.news.newsItems} />
      <Card
          id={data.contact.title.replace(/\s+/g, '-').toLowerCase()}
          title={data.contact.title}
          reversed={false}
          image={data.contact.image.split('/assets/').pop().replace('.jpg', '')}
          ratio={4 / 3}
          cardTitle={data.contact.boxTitle}
          text={converter.makeHtml(data.contact.boxContent)} />
    </Layout>
  )
}